import {forwardRef} from 'react'
import dynamic from 'next/dynamic'

import getSelect, {SelectHOCProps} from './SelectHOC'


const SelectCreatableRef = dynamic(() => import('./_SelectCreatableRef'), {ssr: false})
const SelectCreatableRefForwarded = (props: any, ref: any) => <SelectCreatableRef {...props} forwardedRef={ref} />
const SelectCreatableRefForwarded_ = forwardRef(SelectCreatableRefForwarded)
const SelectCreatableWithHOC = getSelect(({selectRef, ...props}: SelectHOCProps) => <SelectCreatableRefForwarded_ {...props} ref={selectRef} />)
const SelectCreatableWithRef = (props: SelectHOCProps, ref: any) => <SelectCreatableWithHOC isSearchable {...props} selectRef={ref} />

export const SelectCreatable = forwardRef(SelectCreatableWithRef)
