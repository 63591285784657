import {Theme} from 'react-select'
import useTranslation from 'next-translate/useTranslation'

import theme from 'utils/theme'
import {DB, I18N} from 'utils/constants'
import {toTitleCase, sortByProperty} from 'utils/helpers'

import {SelectProps} from './Select'


interface SelectOption {
  label: string
  value: string
}

interface GroupedSelectOption {
  label: string
  options: SelectOption[]
}

export interface SelectHOCProps {
  value?: string
  selectRef?: React.Ref<any>
  options: SelectOption[] | GroupedSelectOption[]
  onValueChange: (value: string) => void
  [key: string]: any // TODO: in time I need to sort out the typings of Select...
}

const getSelectOptions = (options: string[] | {[key: string]: string}, sort: boolean = true, modifier?: 'toLowerCase' | 'toUpperCase' | 'toTitleCase'): SelectOption[] => {

  const selectOptions = [] as SelectOption[]

  if (Array.isArray(options)) {

    const getLabel = (value: string) => {
    
      switch (modifier) {
        case 'toLowerCase':
          return value.toLowerCase()
        case 'toUpperCase':
          return value.toUpperCase()
        case 'toTitleCase':
          return toTitleCase(value)
        default:
          return value
      }
    }
    
    options.forEach(value => {
      selectOptions.push({
        value,
        label: getLabel(value)
      })
    })

  } else {

    Object.keys(options).forEach(value => {
      selectOptions.push({
        value,
        label: options[value]
      })
    })
  }

  return sort
    ? sortByProperty(selectOptions, 'label')
    : selectOptions
}

const getGroupedSelectOptions = (obj: {[key: string]: string[]}, modifier?: 'toTitleCase') => {

  const groupedSelectOptions = [] as GroupedSelectOption[]

  const getLabel = (value: string) => {
    
    switch (modifier) {
      case 'toTitleCase':
        return toTitleCase(value)
      default:
        return value
    }
  }

  for (const key in obj) {
    groupedSelectOptions.push({
      label: key,
      options: obj[key].reduce((arr, value) => {
        arr.push({value, label: getLabel(value)})
        return arr
      }, [])
    })
  }

  return groupedSelectOptions
}

const getValuesAndDisplayNames = (obj: {[key: string]: any}, t: ReturnType<typeof useTranslation>['t'], allType?: 'allTypes' | 'allLines'): SelectOption[] => {

  const valuesAndDisplayNames = [] as SelectOption[]
  const allOption = {} as SelectOption

  for (const key in obj) {
    if (key === DB.ALL) {
      allOption.value = key
      allOption.label = allType ? t(`${I18N.CLIENT}:sizes.${allType}`) : t(`${I18N.USER}:shoe.all`)
    } else {
      valuesAndDisplayNames.push({value: key, label: obj[key].displayName || toTitleCase(key)})
    }
  }

  if (valuesAndDisplayNames.length) {
    return [...valuesAndDisplayNames, allOption] // 'all' option needs to be the last one
  } else {
    return []
  }
}

const getSelect = Select => {

  const _Select = ({value, options, searchable = false, borderless, dropdownIndicatorHidden, onValueChange, ...props}: SelectProps) => {

    const getSelectOption = (): SelectOption | undefined => {
    
      const selectOptions = [] as SelectOption[]

      options.forEach(option => {
        if (option.value) { // SelectOption
          selectOptions.push(option)
        } else { // GroupedSelectOption
          option.options?.forEach((option: SelectOption) => selectOptions.push(option))
        }
      })

      return value 
        ? selectOptions.find(option => option.value === value) || {value, label: value}
        : undefined
    }
  
    const handleChange = (option: SelectOption) => {
  
      if (option.value) {
        onValueChange(option.value)
      }
    }
  
    const customTheme = (baseTheme: Theme) => ({
      ...baseTheme,
      colors: {
        ...baseTheme.colors,
        primary: theme.color
      },
      spacing: {
        ...baseTheme.spacing,
        baseUnit: 3,
        controlHeight: 37
      }
    })
  
    const customStyles = {
      container: (styles: any) => ({
        ...styles,
        width: '100%'
      }),
      control: (styles: any, {isFocused}: any) => ({
        ...styles,
        flexWrap: borderless ? 'unset' : styles.flexWrap,
        borderRadius: borderless ? theme.radius : theme.radiusSharp,
        boxShadow: borderless ? 'none !important' : styles.boxShadow,
        backgroundColor: borderless ? (isFocused ? theme.colorGrayTransparent : theme.colorTransparent) : styles.backgroundColor, 
        borderColor: borderless ? theme.colorTransparent : (isFocused ? theme.color : theme.colorGrayLight),
        ':hover': {
          ...styles[':hover'],
          borderColor: borderless ? theme.colorTransparent : (isFocused ? theme.color : theme.colorGray),
          backgroundColor: borderless ? theme.colorGrayTransparent : styles.backgroundColor
        }
      }),
      singleValue: (styles: any) => ({
        ...styles,
        margin: 0,
        padding: 0,
        overflow: borderless ? 'unset' : styles.overflow,
        maxWidth: borderless ? 'unset' : styles.maxWidth,
        transform: borderless ? 'unset' : styles.transform,
        position: borderless ? 'relative' : styles.position,
        color: borderless ? theme.colorGray : theme.colorBlack
      }),
      valueContainer: (styles: any) => ({
        ...styles,
        padding: 0 + ' ' + theme.size_0_9,
        paddingRight: borderless ? 0 : 'unset',
        flexWrap: borderless ? 'unset' : styles.flexWrap,
        overflow: borderless ? 'unset' : styles.overflow
      }),
      dropdownIndicator: (styles: any) => ({
        ...styles,
        color: theme.colorGrayLight,
        visibility: dropdownIndicatorHidden ? 'hidden' : 'visible'
      }),
      placeholder: (styles: any) => ({
        ...styles,
        color: theme.colorGrayLight
      }),
      indicatorSeparator: (styles: any) => ({
        ...styles,
        visibility: 'collapse'
      }),
      option: (styles: any, state: any) => ({
        ...styles,
        color: state.isSelected ? theme.colorWhite : theme.colorBlack + ' !important',
        backgroundColor: state.isSelected ? theme.color + ' !important' : theme.colorTransparent,
        ':hover': {
          ...styles[':hover'],
          backgroundColor: theme.colorAccent
        }
      })
    }

    return (
      <Select
        options={options}
        theme={customTheme}
        styles={customStyles}
        value={getSelectOption()}
        isSearchable={searchable}
        inputId={`select-${props.placeholder}`}
        onChange={handleChange}
        {...props}
      />
    )
  }

  return _Select
}

export {
  getSelect as default,
  getSelectOptions, 
  getGroupedSelectOptions, 
  getValuesAndDisplayNames
}
