import dynamic from 'next/dynamic'
import {forwardRef} from 'react'

import getSelect, {SelectHOCProps} from './SelectHOC'


export interface SelectProps extends SelectHOCProps {
  searchable?: boolean
  borderless?: boolean
  dropdownIndicatorHidden?: boolean
}

const SelectRef = dynamic(() => import('./_SelectRef'), {ssr: false})
const SelectRefForwarded = (props: any, ref: any) => <SelectRef {...props} forwardedRef={ref} />
const SelectRefForwarded_ = forwardRef(SelectRefForwarded)
const SelectWithHOC = getSelect(({selectRef, ...props}: SelectProps) => <SelectRefForwarded_ {...props} ref={selectRef} />)
const SelectWithRef = (props: SelectProps, ref: any) => <SelectWithHOC {...props} selectRef={ref} />

export const Select = forwardRef(SelectWithRef)
