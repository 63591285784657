import router from 'next/router'
import {useState, useEffect} from 'react'
import useTranslation from 'next-translate/useTranslation'

import {locales as _locales} from '../../i18n'

import {COOKIE} from 'utils/constants'
import {getSelectOptions, Select} from './Select'
import {postMessage, setCookie} from 'utils/helpers'


const LanguageSelector = () => {

  const {lang} = useTranslation()
  const [locale, setLocale] = useState(lang)
  const [locales, setLocales] = useState(_locales)

  useEffect(() => {
    
    setLocales(router.locales)
  }, [])

  useEffect(() => {

    if (locale !== lang) {

      setCookie(COOKIE.LOCALE, locale) // own domain
      postMessage({setLocale: locale}) // client domain
      
      const {pathname, asPath, query} = router
      router.replace({pathname, query}, asPath, {locale})
    }
  }, [lang, locale])

  return (
    <Select 
      borderless
      isSearchable={false}
      value={locale.toUpperCase()}
      options={getSelectOptions(locales as unknown as string[], true, 'toUpperCase').filter(({value}) => value !== locale)}
      onValueChange={setLocale}
    />
  )
}

export default LanguageSelector
